// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-marcin-treder-js": () => import("/opt/build/repo/src/pages/about-marcin-treder.js" /* webpackChunkName: "component---src-pages-about-marcin-treder-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-conversion-optimization-js": () => import("/opt/build/repo/src/pages/portfolio/conversion-optimization.js" /* webpackChunkName: "component---src-pages-portfolio-conversion-optimization-js" */),
  "component---src-pages-portfolio-ecommerce-homepage-design-js": () => import("/opt/build/repo/src/pages/portfolio/ecommerce-homepage-design.js" /* webpackChunkName: "component---src-pages-portfolio-ecommerce-homepage-design-js" */),
  "component---src-pages-portfolio-ecommerce-mobile-app-design-js": () => import("/opt/build/repo/src/pages/portfolio/ecommerce-mobile-app-design.js" /* webpackChunkName: "component---src-pages-portfolio-ecommerce-mobile-app-design-js" */),
  "component---src-pages-portfolio-ecommerce-video-portal-js": () => import("/opt/build/repo/src/pages/portfolio/ecommerce-video-portal.js" /* webpackChunkName: "component---src-pages-portfolio-ecommerce-video-portal-js" */),
  "component---src-pages-portfolio-index-js": () => import("/opt/build/repo/src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-open-source-adventure-js": () => import("/opt/build/repo/src/pages/portfolio/open-source-adventure.js" /* webpackChunkName: "component---src-pages-portfolio-open-source-adventure-js" */),
  "component---src-pages-portfolio-paper-prototyping-redesigned-js": () => import("/opt/build/repo/src/pages/portfolio/paper-prototyping-redesigned.js" /* webpackChunkName: "component---src-pages-portfolio-paper-prototyping-redesigned-js" */),
  "component---src-pages-portfolio-treder-design-branding-js": () => import("/opt/build/repo/src/pages/portfolio/treder-design-branding.js" /* webpackChunkName: "component---src-pages-portfolio-treder-design-branding-js" */),
  "component---src-pages-portfolio-uxpin-new-editor-js": () => import("/opt/build/repo/src/pages/portfolio/uxpin-new-editor.js" /* webpackChunkName: "component---src-pages-portfolio-uxpin-new-editor-js" */)
}

